import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import rootReducer from './reducers';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import createSagaMiddleware from 'redux-saga'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import UserViewContainer from './containers/UserViewContainer';
import ActivityContainer from './containers/ActivityContainer';

import 'dayjs/locale/en-gb';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { createActivity, createActivities, fetchActivityDetail, createUserActivityShare, removeUserActivityShare } from './sagas/activityDetailSaga'
import {fetchUserInfo, createUserActivity, updateUserActivity, acceptShareInvitation, deleteUserActivity} from './sagas/userInfoSaga'
import {fetchIdToken, refreshIdToken} from './sagas/authTokenSaga'
import Login from './components/Login';
import Logout from './components/Logout';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {createTheme, ThemeProvider} from "@mui/material";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#00b467',
      contrastText: '#fff',
    },
    secondary:{
      main: '#00e281',
      light: '#00fc90',
      dark: '#1565c0',
      contrastText: '#000',
    },
    warning: {
      main: '#ffa726',
      contrastText: '#666666',
    },
    background: {
      paper: '#333333',
    }
  },
});

let store = createStore(rootReducer, {
  userInfo: {
    loading: true,
    firstName: "",
    lastName: "",
    token: "",
    userActivities: {
      limit: 0,
      items:[]
    },
    sharedWithMe: {
      items:[]
    }
  },
  activityDetail: {
    loading: true,
    name: "",
    measure_unit: "",
    measure_unit_name: "",
    activities:[],
    shares:[]
  },
},
composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(fetchUserInfo);
sagaMiddleware.run(createUserActivity);
sagaMiddleware.run(updateUserActivity);
sagaMiddleware.run(fetchActivityDetail);
sagaMiddleware.run(fetchIdToken);
sagaMiddleware.run(refreshIdToken);
sagaMiddleware.run(createActivity);
sagaMiddleware.run(createActivities);
sagaMiddleware.run(createUserActivityShare);
sagaMiddleware.run(acceptShareInvitation);
sagaMiddleware.run(deleteUserActivity);
sagaMiddleware.run(removeUserActivityShare);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <h1>Oops</h1>,
    children: [
      {
        path: "/",
        element: <UserViewContainer />
      },
      {
        path: "/activities/:activityID",
        element: <ActivityContainer />
      }
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/logout",
    element: <Logout />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(navigator.language)
const locale = navigator.language.toLowerCase() === 'en' ? 'en' : 'en-gb';
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <ThemeProvider theme={darkTheme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
