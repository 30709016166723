
import React, {useState} from 'react';
import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    useTheme,
    Button,
    Alert,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AppBarOffset from "./AppBarOffset";
import Grid2 from "@mui/material/Grid2";

function AddUserActivity(props) {
    const [activityName, setActivityName] = useState(props.defaultName);
    const [activityMeasure, setActivityMeasure] = useState(props.defaultMeasure);
    const [activityMeasureName, setActivityMeasureName] = useState(props.defaultMeasureName);
    const [activityInitialLoad, setActivityInitialLoad] = useState(props.defaultInitialLoad);
    const [activityTargetLoad, setActivityTargetLoad] = useState(props.defaultTargetLoad);
    const [activityPreTimelineDays, setActivityPreTimelineDays] = useState(props.defaultPreTimelineDays);
    const [activityPostTimelineDays, setActivityPostTimelineDays] = useState(props.defaultPostTimelineDays);

    const[hasValidationErrors, setHasValidationErrors] = useState(false);
    const[validationErrors, setValidationErrors] = useState([]);

    const theme = useTheme();
    const handleAddClick = () => {
        let validationErrors = [];
        let hasValidationErrors = false;

        if(!activityName) {
            validationErrors.push("Activity name is required");
            hasValidationErrors = true;
        }

        if(!activityMeasureName){
            validationErrors.push("Measurement label is required");
            hasValidationErrors = true;
        }

        setHasValidationErrors(hasValidationErrors)
        setValidationErrors(validationErrors);

        if (!hasValidationErrors) {
            props.onAdd(activityName, activityMeasure, activityMeasureName, activityPreTimelineDays, activityPostTimelineDays, activityInitialLoad, activityTargetLoad); 
            props.onClose();
        }
    }
    return (<>
        <AppBar position="fixed" sx={{backgroundColor: theme.palette.common.black}} >
            <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={props.onClose}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                    {activityName ? activityName : "New Activity"}
                </Typography>

                      <Button
                        size="medium"
                        aria-label="Add"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleAddClick}
                        color="primary"
                        variant={"contained"}
                      >
                          Save
                      </Button>
            </Toolbar>
        </AppBar>
        <AppBarOffset />
        <Grid2 container spacing={2} sx={{marginTop: '15px', padding:'15px'}}>
                {hasValidationErrors &&
                  <Grid2 size={12}>
                      <Alert severity="error" variant={"filled"}>
                          <strong>Please correct the following errors</strong>
                          <ul>
                              {validationErrors.map((e) => <li>{e}</li>)}
                          </ul></Alert>
                  </Grid2>
                }
                <Grid2 size={12}>
                        <TextField id="activityName" label="Activity Name" value={activityName} onChange={(e) => {setActivityName(e.target.value)}} fullWidth={true} />
                </Grid2>
                <Grid2 size={6}>
                    <FormControl fullWidth>
                        <InputLabel id="measure-select-label">Measure</InputLabel>
                        <Select
                          labelId="measure-select-label"
                          id="activityMeasure"
                          value={activityMeasure}
                          label="Measure"
                          onChange={(e) => {setActivityMeasure(e.target.value)}}
                        >
                            <MenuItem value={"Reps"}>Repetitions</MenuItem>
                            <MenuItem value={"Distance"}>Distance</MenuItem>
                            <MenuItem value={"Time"}>Time</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
            <Grid2 size={6}>
                <TextField id="measureLabel" label="Measurement label" value={activityMeasureName} onChange={(e) => {setActivityMeasureName(e.target.value)}} fullWidth={true} />
            </Grid2>
            <Grid2 size={6}>
                <FormControl fullWidth>
                    <InputLabel id="historical-select-label">Historical timeline</InputLabel>
                    <Select
                      labelId="historical-select-label"
                      id="beforeDays"
                      value={activityPreTimelineDays}
                      label="Historical timeline"
                      onChange={(e) => {setActivityPreTimelineDays(e.target.value)}}
                    >
                        <MenuItem value={"7"}>1 week</MenuItem>
                        <MenuItem value={"14"}>2 weeks</MenuItem>
                        <MenuItem value={"28"}>4 weeks</MenuItem>
                        <MenuItem value={"42"}>6 weeks</MenuItem>
                        <MenuItem value={"56"}>8 weeks</MenuItem>
                        <MenuItem value={"84"}>12 weeks</MenuItem>
                        <MenuItem value={"180"}>6 months</MenuItem>
                        <MenuItem value={"360"}>1 year</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={6}>
                <FormControl fullWidth>
                    <InputLabel id="future-select-label">Future timeline</InputLabel>
                    <Select
                      labelId="future-select-label"
                      id="postDays"
                      value={activityPostTimelineDays}
                      label="Future timeline"
                      onChange={(e) => {setActivityPostTimelineDays(e.target.value)}}
                    >
                        <MenuItem value={"7"}>1 week</MenuItem>
                        <MenuItem value={"14"}>2 weeks</MenuItem>
                        <MenuItem value={"28"}>4 weeks</MenuItem>
                        <MenuItem value={"42"}>6 weeks</MenuItem>
                        <MenuItem value={"56"}>8 weeks</MenuItem>
                        <MenuItem value={"84"}>12 weeks</MenuItem>
                        <MenuItem value={"180"}>6 months</MenuItem>
                        <MenuItem value={"360"}>1 year</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={6}>
                <TextField id="initialLoad" type={"number"} label="Initial load" value={activityInitialLoad} onChange={(e) => {setActivityInitialLoad(e.target.value)}} fullWidth={true} />
            </Grid2>
            <Grid2 size={6}>
                <TextField id="targetLoad" label="Target load" value={activityTargetLoad} onChange={(e) => {setActivityTargetLoad(e.target.value)}} fullWidth={true} />
            </Grid2>

        </Grid2>
    </>)
}

export default AddUserActivity;