import React from 'react';
import {auth0} from '../environment';

const createAccount = () => {
  window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f&screen_hint=signup`;
}

const loginClick = () => {
  window.location.href = `${auth0.host}/authorize?response_type=code&client_id=${auth0.clientId}&redirect_uri=${auth0.redirect}&scope=openid%20profile%20offline_access&state=f`;
  }

  function Login() {
  return (<>
    <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-col" style={{backgroundColor: 'black'}}>
          <div className="ms-auth-form">
            <div className="needs-validation" noValidate="">
              <img src="../assets/img/x-tracked-logo-full.png" alt="logo" />
              <p>v1.2.11</p>

              <div style={{marginTop: "30px"}}>
                <button className="btn btn-primary mt-4 d-block w-100" onClick={loginClick}  >
                  Login
                        </button>
              </div>
              <p className="mb-0 mt-3 text-center" style={{color: '#eeeeee'}}>Don't have an account? <a className="btn-link" href="#" onClick={createAccount}>Create Account</a> </p>
            </div>
          </div>
      </div>

    </div>
  </>
  );
}

export default Login;
