import React from 'react';
import {CircularProgress} from "@mui/material";


function PreLoader() {
  return (<div style={{marginTop: '40%'}}><CircularProgress /></div>
    );
}

export default PreLoader;
