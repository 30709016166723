import React, {useEffect, useState, useCallback} from 'react';
import { connect } from 'react-redux'
import './App.css';

import { Outlet } from "react-router-dom";

import Login from './components/Login';

import Cookies from 'js-cookie';

import {fetchUserInfo, fetchIdToken, refreshIdToken} from './actions/userInfoAction'

function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  const {
    fetchUserInfo,
    fetchIdToken,
    refreshIdToken
  } = props;


  const checkLogin = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const isAuth0Redirect = params.has("code") && params.has("state");
    const refreshToken = Cookies.get("rt");
  
    if (isAuth0Redirect) {
      fetchIdToken(params.get("code"));
      window.history.replaceState({}, document.title, "/");
    }
    else if (refreshToken) {
      refreshIdToken(refreshToken);
    }
  }, [fetchIdToken, refreshIdToken]);
 
  useEffect(() => {
    checkLogin();
    setIsLoading(false);
  },[checkLogin, fetchUserInfo]);

  if (isLoading) {
    return <div></div>
  }

  return (
    <div className={"App"}>
      <main className="body-content">
      { props.userInfo && props.userInfo.token !== "" ?
          <Outlet />
        : <Login />}
      </main>
    </div>
  );
}

const mapStateToProps = (state = {}) => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUserInfo,
    fetchIdToken,
    refreshIdToken,
  }
)(App);
