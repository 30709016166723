import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ActivityGraph from './ActivityGraph.js';
import PreLoader from './Preloader';
import AddActivity from './AddActivity';
import ShareActivity from './ShareActivity';
import Planner from './Planner';
import AddUserActivity from './AddUserActivity.js';
import { getGraphPoints } from '../graphCalculator.js';
import {Alert, AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AppBarOffset from "./AppBarOffset";
import Grid2 from "@mui/material/Grid2";


const getIsMobile = () => window.innerWidth <= 1200;

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, []);

  return isMobile;
}

function UserActivityView(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    activityDetail,
    onFetchActivityDetail
  } = props;

  const { activityID } = useParams()
  const [isAdding, setIsAdding] = useState({ dialogOpen: false, defaults: null });
  const [isSharing, setIsSharing] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [pendingActivities, setPendingActivities] = useState();
  const [currentPlannerDay, setCurrentPlannerDay] = useState(null);
  const [showFullCalendarOnMobile, setShowFullCalendarOnMobile] = useState(false);
  /*const [showAllPlanned, setShowAllPlanned] = useState(false);
  const [showAllActual, setShowAllActual] = useState(false);*/

  const isMobile = useIsMobile();

  const updatePlanned = (newPlan) => {
    var pendingCopy = pendingActivities ?? JSON.parse(JSON.stringify(activityDetail.activities));
    let newActivities = pendingCopy;

    for (var i = 0; i < newPlan.length; i++) {
      let value = newPlan[i].value;
      let time = newPlan[i].time;
      let type = newPlan[i].type;

      if (value === "") {
        value = 0;
      }
  
      value = parseFloat(value);
  
      if (value < 0) {
        value = 0;
      };
  
      var found = false;

      newActivities = newActivities.map(activity => {
        if (activity.Time === time && activity.IsPlanned) {
          found = true;
          if (type === "practice") {
            activity.PracticeValue = value;
          }
          else {
            activity.CompetitiveValue = value;
          }
          setCurrentPlannerDay(activity);
        }
        return activity;
      });
  
      if (!found) {
        let newActivity = { Time: time, IsPlanned: true, PracticeValue: 0, CompetitiveValue: 0 };
        if (type === "practice") {
          newActivity.PracticeValue = value;
        }
        else {
          newActivity.CompetitiveValue = value;
        }
        newActivities.push(newActivity);
        setCurrentPlannerDay(newActivity);
      }
    }


    setPendingActivities(newActivities.sort((a, b) => new Date(a.Time) - new Date(b.Time)));
  }

  const onPlannerClosed = () => {
    setPendingActivities(null);
  }

  const savePendingActivities = () => {
    var now = new Date();
    var nowDay = new Date();
    nowDay.setDate(now.getDate());
    nowDay.setHours(0, 0, 0, 0);
    let futurePlannedActivities = pendingActivities.filter((act) => act.IsPlanned && new Date(act.Time) >= nowDay);
    let activitiesToSave = futurePlannedActivities.map(act => {
      return {
        IsPlanned: true,
        Time: act.Time,
        PracticeValue: parseFloat(act.PracticeValue, 2) ?? 0.0,
        CompetitiveValue: parseFloat(act.CompetitiveValue, 2) ?? 0.0,
        UserActivityPubID: activityDetail.pub_id,
      }
    });

    props.onCreateActivities(activitiesToSave, activityDetail.pub_id);
    setPendingActivities(null);
    setShowFullCalendarOnMobile(false);
  }

  useEffect(() => {
    onFetchActivityDetail(activityID);
  }, [onFetchActivityDetail, activityID]);


  if (activityDetail.loading) {
    return (<PreLoader />)
  }


  // Days before today to show on the graph
  let preDays = activityDetail.pre_timeline_days;

  // Days after today to show on the graph
  let postDays = activityDetail.post_timeline_days;

  // Starting load
  let startingLoad = activityDetail.starting_load;

  let currentActivities = activityDetail.activities;
  if (pendingActivities) {
    currentActivities = pendingActivities;
  }

  let graphData = getGraphPoints(preDays, postDays, startingLoad, currentActivities);

  if (isAdding.dialogOpen) {
    return <AddActivity
      activityContext={activityDetail}
      defaults={isAdding.defaults}
      onAdd={(activityDate, practiceValue, competitiveValue, isPlanned, flag, comment) => {
          props.onCreateActivity(activityID, activityDate, practiceValue, competitiveValue, isPlanned, flag, comment)
        }
      }
      onClose={() => setIsAdding({ dialogOpen: false, defaults: null })} />
  }

  if (isSharing) {
    return <ShareActivity activityContext={activityDetail}
           onShare={(shareToEmail, readOnly) => {
                        props.onShareUserActivity(activityID, shareToEmail, readOnly);
                      }}
           onRemoveShare={(shareID) => {
                       props.onRemoveShare(shareID);
                     }}
           onClose={() => setIsSharing(false)} />
  }

  if (settingsOpen) {
    return <AddUserActivity
      defaultName={activityDetail.name}
      defaultMeasure={activityDetail.measure_unit}
      defaultMeasureName={activityDetail.measure_unit_name}
      defaultPreTimelineDays={activityDetail.pre_timeline_days}
      defaultPostTimelineDays={activityDetail.post_timeline_days}
      defaultInitialLoad={activityDetail.starting_load}
      defaultTargetLoad={activityDetail.target_load}
      onAdd={(activityName, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) => { props.onUpdateUserActivity(activityID, activityName, measure, measureLabel, preDays, postDays, initialLoad, targetLoad) }}
      onClose={() => setSettingsOpen(false)} />
  }

  console.log(window.innerWidth);
  return (<>
      <AppBar position="fixed" sx={{backgroundColor: theme.palette.common.black}} >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate('/')}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
            {activityDetail.name}
          </Typography>

          {!activityDetail.read_only &&
            <div>
              <IconButton
                size="medium"
                aria-label="Settings"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setSettingsOpen(true)}
                color="inherit"
              >
                <SettingsIcon />
              </IconButton>
              <IconButton
                size="medium"
                aria-label="Share"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setIsSharing(true)}
                color="inherit"
              >
                <ShareIcon />
              </IconButton>
              <IconButton
                size="medium"
                aria-label="Add"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setIsAdding({ dialogOpen: true, defaults: null })}
                color="inherit"
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          }
        </Toolbar>
      </AppBar>
      <AppBarOffset />
      <Grid2 container size={12} sx={{marginTop:'5px'}}>

        {(pendingActivities) &&
          <Grid2 size={12}>
            <Alert icon={false} severity="warning" variant={"filled"} sx={{margin:'5px'}}
                   action={<>
                     <Button color="inherit" size="medium" onClick={onPlannerClosed}>
                       CANCEL
                     </Button>&nbsp;
                     <Button color="inherit" size="medium" color={"primary"} variant="contained" onClick={savePendingActivities}>
                       SAVE
                     </Button></>
                   }>
              <Typography variant={"subheading2"}>Unsaved changes</Typography>
            </Alert></Grid2>}
        {!showFullCalendarOnMobile && <Grid2 size={{xs:12, lg:8}} sx={{ minHeight: '40vh', flexGrow:1 }}>
          {!activityDetail ? <div className="ms-panel-body text-center">No data</div> : <>
            <ActivityGraph data={graphData} />
          </>
            }
        </Grid2>
        }
        <Grid2 size={{xs:12, lg:4}}>
          {isMobile && <div style={{textAlign: 'right'}}>
          {!showFullCalendarOnMobile ?
            <Button title="Expand calendar" onClick={() => { setShowFullCalendarOnMobile(!showFullCalendarOnMobile) }}><i className="material-icons fs-16">calendar_month</i><i className="material-icons fs-16">north_east</i></Button> :
            <Button title="Collapse calendar" onClick={() => { setShowFullCalendarOnMobile(!showFullCalendarOnMobile) }}><i className="material-icons fs-16">calendar_month</i><i className="material-icons fs-16">south_west</i></Button>
          }</div>
          }
          <Planner
            preDays={activityDetail.pre_timeline_days}
            postDays={activityDetail.post_timeline_days}
            activities={currentActivities}
            currentPlannerDay={currentPlannerDay}
            isDirty={pendingActivities ? true : false}
            onAddActivity={(defaults) => setIsAdding({ dialogOpen: true, defaults: defaults })}
            onClose={onPlannerClosed}
            onSave={savePendingActivities}
            updatePlanned={updatePlanned}
            currentChronic={graphData.todayChronic}
            isMobile={!showFullCalendarOnMobile && isMobile}
            readOnly={activityDetail.read_only} />
        </Grid2>
      </Grid2>
  </>
  )
}

export default UserActivityView;
