
import React, { useState } from 'react';
import {
    Alert,
    AppBar,
    Button,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBarOffset from "./AppBarOffset";
import Grid2 from "@mui/material/Grid2";
import MoodIcon from '@mui/icons-material/Mood';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HealingIcon from '@mui/icons-material/Healing';

function AddActivity(props) {
    const theme = useTheme();
    const [activityDate, setActivityDate] = useState(props.defaults ? props.defaults.Time : "");
    const [practiceValue, setPracticeValue] = useState(props.defaults ? props.defaults.PracticeValue : "");
    const [competitiveValue, setCompetitiveValue] = useState(props.defaults ? props.defaults.CompetitiveValue : "");
    const [isPlanned, setIsPlanned] = useState(props.defaults ? props.defaults.IsPlanned : false);
    const [flag, setFlag] = useState(props.defaults ? props.defaults.Flag : "");
    const [comment, setComment] = useState(props.defaults ? props.defaults.Comment : "");

    const[hasValidationErrors, setHasValidationErrors] = useState(false);
    const[validationErrors, setValidationErrors] = useState([]);

    const handleAddClick = () => {
        let validationErrors = [];
        let hasValidationErrors = false;

        if(!activityDate) {
            validationErrors.push("Activity date is required");
            hasValidationErrors = true;
        }

        setHasValidationErrors(hasValidationErrors)
        setValidationErrors(validationErrors);

        if (!hasValidationErrors) {
            props.onAdd(activityDate, practiceValue, competitiveValue, isPlanned, flag, comment);
            props.onClose();
        }
    }

    return (<>
        <AppBar position="fixed" sx={{backgroundColor: theme.palette.common.black}} >
            <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={props.onClose}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                    New {props.activityContext.name} activity
                </Typography>
                <Button
                  size="medium"
                  aria-label="Add"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleAddClick}
                  color="primary"
                  variant={"contained"}
                >
                    Add
                </Button>
            </Toolbar>
        </AppBar>
        <AppBarOffset />
        <Grid2 container spacing={2} sx={{marginTop: '15px', padding:'15px'}}>
            {hasValidationErrors &&
              <Grid2 size={12}>
                  <Alert severity="error" variant={"filled"}>
                      <strong>Please correct the following errors</strong>
                      <ul>
                          {validationErrors.map((e) => <li>{e}</li>)}
                      </ul></Alert>
              </Grid2>
            }
            <Grid2 size={12}>
                <TextField InputLabelProps={{ shrink: true }} type={"date"} label="Activity Date" value={activityDate} onChange={(e) => setActivityDate(e.target.value)} fullWidth />
            </Grid2>
            <Grid2 size={6}>
                <TextField type={"number"} label={`Practice ${props.activityContext.measure_unit_name}`} value={practiceValue} onChange={(e) => setPracticeValue(e.target.value)} fullWidth />
            </Grid2>
            <Grid2 size={6}>
                <TextField type={"number"} label={`Competition ${props.activityContext.measure_unit_name}`} value={competitiveValue} onChange={(e) => setCompetitiveValue(e.target.value)} fullWidth />
            </Grid2>
            <Grid2 size={6}>
                <ToggleButtonGroup
                  exclusive
                  value={isPlanned}
                  onChange={(e, newVal) => {if(newVal !== null) {setIsPlanned(newVal);}}}
                  aria-label="Type"
                >
                    <ToggleButton value={true} aria-label="bold">
                        Planned
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="italic">
                        Actual
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 size={6}>
                <ToggleButtonGroup
                  exclusive
                  value={flag}
                  onChange={(e, newVal) => setFlag(newVal)}
                  aria-label="Tags"
                >
                    <ToggleButton value={"Good"} aria-label="bold">
                        <MoodIcon color={"success"} />
                    </ToggleButton>
                    <ToggleButton value={"Sore"} aria-label="italic">
                        <HealingIcon color={"warning"} />
                    </ToggleButton>
                    <ToggleButton value={"Injured"} aria-label="italic">
                        <HealthAndSafetyIcon color={"error"} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid2>
            <Grid2 size={12}>
                <TextField
                  id="comment"
                  label="Comment"
                  multiline
                  rows={2}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  fullWidth={true}
                />
            </Grid2>
        </Grid2>
    </>)
}

export default AddActivity;