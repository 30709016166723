
import React, { useState } from 'react';
import {
    AppBar,
    Button,
    FormControlLabel,
    IconButton, ListItem, ListItemText,
    Switch,
    TextField,
    Toolbar,
    Typography,
  List,
    useTheme
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBarOffset from "./AppBarOffset";
import Grid2 from "@mui/material/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from '@mui/icons-material/Visibility';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DeleteIcon from "@mui/icons-material/Delete";

function ShareActivity(props) {
    const theme = useTheme();
    const [shareToEmail, setShareToEmail] = useState();
    const [readOnly, setReadOnly] = useState(false);

    return (<>
        <AppBar position="fixed" sx={{backgroundColor: theme.palette.common.black}} >
            <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={props.onClose}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                    Shares
                </Typography>
            </Toolbar>
        </AppBar>
        <AppBarOffset />

        {props.activityContext.shares.length > 0 &&
          <Grid2 container spacing={2} sx={{maxWidth: '800px', marginTop: '15px', padding:'15px', justifyContent: 'center'}}>
              <Typography variant="h6" color={"secondary"}>
                  Currently shared to:
              </Typography>
              <List sx={{width: '100%'}}>
                  {props.activityContext.shares.map((share) =>  (
                    <ListItem
                      divider={true}
                        secondaryAction={<>
                                {share.ReadOnly && <VisibilityIcon sx={{color: theme.palette.text.icon}} aria-label={"Read-only"} title={"Read-only"} />}
                                {share.Pending && <HourglassTopIcon sx={{ml:1, color: theme.palette.text.icon}} aria-label={"Pending"} />}
                                <IconButton sx={{ml:3}} edge="end" aria-label="Remove share" onClick={(e) => {
                                    e.preventDefault(); // We're inside a link, so we need to prevent that click redirecting us
                                    window.confirm(`Are you sure you want to remove this share?`) && props.onRemoveShare(share.PubID)}}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </>}>
                          <ListItemText
                            primary={<>{share.SharedTo}</>}
                            sx={{color: theme.palette.text.primary}}
                          />
                    </ListItem>))
                  }
              </List>
          </Grid2>
        }

        <Grid2 container spacing={2} sx={{maxWidth: '800px', marginTop: '15px', padding:'15px'}}>
            <Grid2 size={12}>
                <Typography variant="h6" color={"primary"}>
                Add new share
            </Typography>
            </Grid2>
            <Grid2 size={12}>
                <TextField id="shareTo" label="Share to email" value={shareToEmail} onChange={(e) => {setShareToEmail(e.target.value)}} fullWidth={true} />
            </Grid2>
            <Grid2 size={12}>
                <FormControlLabel sx={{color:'white'}} control={<Switch onChange={(e) => {setReadOnly(e.target.checked)}} />} label="Read-only" />
            </Grid2>
            <Grid2 size={12}>
                <Button
                  size="large"
                  aria-label="Add"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => props.onShare(shareToEmail, readOnly)}
                  color="primary"
                  variant={"contained"}
                >
                    <AddCircleIcon sx={{ mr: 1 }} />
                    Share
                </Button>
            </Grid2>
        </Grid2>
    </>)
}

export default ShareActivity;